/* html {
  font-size: 14px;
} */

body {
  font-family: "Kanit", "Roboto", "Helvetica", "Arial", "sans-serif";
  margin: 0;
  overflow-x: hidden;
  max-width: 100%;
  background-color: #17171c;
}

#goHomeLogo {
  color: #afb0cc;
  font-size: 32px;
  line-height: 21.25px;
  margin-top: 5.5px;
  margin-bottom: 0px;
  font-weight: normal;
}

#AppButtonBar .MuiButtonBase-root:hover,
#AppButtonBar .MuiIconButton-root:hover {
  background-color: transparent;
  color: #9092fe;
}
#AppButtonBar .MuiButtonBase-root {
  min-width: 64px;
}
#AppButtonBar .MuiIconButton-label {
  margin-top: 8px;
  margin-right: -20px;
}

#AppButtonBar .MuiMenuItem-root {
  font-size: 0.875rem;
}

#docButton {
  margin-left: -10px;
}

.searchInput fieldset {
  border-color: #6667ab;
  border-radius: 50px;
}

.searchInput svg {
  color: #afb0cc;
}

.searchInput .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #9092fe;
}

.dropdown-menu .MuiPaper-root {
  margin-top: 3rem;
}

.dropdown-menu ul {
  max-height: inherit;
  /* max-width: inherit; */
  overflow: auto;
}

/* #root {
  background: #17171c;
} */

/* #mainContent {
  overflow-y: auto;
} */

::-webkit-scrollbar {
  width: 5px;
}

/* ::-webkit-scrollbar-track {
  background: rgba(245, 245, 245, .5);
  z-index: 1;
} */

::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 0.5);
  border-radius: 5px;
}

input,
span,
.MuiTypography-body1,
p {
  font-family: "Kanit", "Roboto", "Helvetica", "Arial", "sans-serif";
}

/* .MuiListItemText-secondary {
  text-overflow: ellipsis;
  overflow: hidden;
} */
